<template>
  <div class="user-details">
    <div class="title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/user/list' }">用户列表</el-breadcrumb-item>
        <el-breadcrumb-item>{{breadcrumb}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 表单数据 -->
    <div class="myform">
      <el-form :model="userForm" :rules="rules" ref="rulForm" class="demo-ruleForm">
        <div class="form-top">
          <div class="form-compo">
            <el-form-item label="手机号码" prop="phone">
              <el-input class="form-input" size="small" v-model.number="userForm.phone"></el-input>
            </el-form-item>
            <el-form-item class="compo-center" label="密码" prop="password">
              <el-input type="password" class="form-input" size="small" v-model="userForm.password"></el-input>
            </el-form-item>
            <el-form-item label="用户名称" prop="name">
              <el-input class="form-input" size="small" v-model="userForm.name"></el-input>
            </el-form-item>
          </div>
          <div class="form-compo">
            <el-form-item label="所属机构" prop="add_chain_id">
              <el-select size="small" v-model="userForm.add_chain_id" placeholder="请选择机构" :disabled="disabled">
                <el-option v-for="item in chain_label" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="compo-center" label="角色权限" prop="jurisdiction_id">
              <el-select size="small" v-model="userForm.jurisdiction_id" placeholder="请选择角色权限">
                <el-option v-for="item in jurisdiction_label" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="compo-center" label="'是否允许访问大嘴鸟AI'" prop="bmb_ai_id">
              <el-select size="small" v-model="userForm.bmb_ai_id" placeholder="请选择访问AI权限">
                <el-option v-for="item in bmb_ai_list" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="form-button">
          <el-form-item>
            <el-button type="warning" size="mini" @click="submitForm">确定</el-button>
            <el-button size="mini" @click="resetForm">返回</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { downchain, addUser, shopUserDetails, getJurisdiction } from '../../../request/api'

export default {
  data () {
    return {
      breadcrumb: '新增用户',
      userForm: {
        phone: '',
        password: '',
        name: '',
        add_chain_id: '',
        shop_user_id: '',
        jurisdiction_id: '',
        bmb_ai_id: ''
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { type: 'number', message: '手机号码必须是数字值', trigger: 'blur'}
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入用户名称', trigger: 'blur' }
        ],
        add_chain_id: [
          { required: true, message: '请选择绑定的公司', trigger: 'blur' }
        ],
        jurisdiction_id: [
          { required: true, message: '请选择角色权限', trigger: 'blur' }
        ],
        bmb_ai_id: [
          { required: true, message: '请选择访问AI权限', trigger: 'blur' }
        ]
      },
      chain_label: [],
      jurisdiction_label: [],
      disabled: false,
      bmb_ai_list: [
        { 'label': '不允许', 'value': 1 },
        { 'label': '允许', 'value': 2 }
      ]
    }
  },
  methods: {
    async submitForm () {
      this.$refs.rulForm.validate(valid => {
        if (!valid) {
          console.log('error submit!')
          return false
        }
      })
      if (this.userForm.bmb_ai_id === '' || this.userForm.phone === '' || this.userForm.password === '' || this.userForm.name === '' || this.userForm.add_chain_id === '' || this.userForm.jurisdiction_id === '') {
        this.$message.warning('请完善字段信息')
        return
      }

      const res = await addUser(this.userForm)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      this.$message.success('添加成功')
      this.$router.push({ path: '/user/list' })
    },
    resetForm () {
      this.$router.back()
    },
    // 获取下级机构列表
    async shopDownChain () {
      const res = await downchain()
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      res.data.data.chainList.forEach(element => {
        let temp = {}
        temp.label = element.chain_name
        temp.value = element.shop_chain_id
        this.chain_label.push(temp)
      })
    },
    // 获取角色权限列表
    async shopJurisdiction () {
      const res = await getJurisdiction()
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      res.data.data.forEach(element => {
        let temp = {}
        temp.label = element.jurisdiction_name
        temp.value = element.shop_jurisdiction_id
        this.jurisdiction_label.push(temp)
      })
    },
    // 初始化数据
    async init () {
      if (this.userForm.shop_user_id !== undefined) {
        this.breadcrumb = '编辑用户'
        const res = await shopUserDetails(this.userForm.shop_user_id)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        let userData = res.data.data
        this.userForm.phone = userData.phone
        this.userForm.name = userData.name
        this.userForm.add_chain_id = userData.add_chain_id
        this.userForm.jurisdiction_id = userData.jurisdiction_id
        this.userForm.bmb_ai_id = userData.bmb_ai_id
        this.disabled = true
      }
    }
  },
  mounted () {
    this.shopDownChain()
    this.shopJurisdiction()
    this.init()
  },
  created () {
    this.userForm.shop_user_id = this.$route.query.shop_user_id
  }
}
</script>

<style lang="less" scoped>
.user-details {
  margin: 10px;
  height: 100%;
  .title {
    padding: 10px;
    background: #fff;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-bottom: 10px;
  }
  .myform {
    height: 100%;
    .demo-ruleForm {
      height: 100%;
      .form-top {
        background: #fff;
        padding: 22px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        .form-compo {
          display: flex;
          .compo-center {
            margin: 0 20px;
          }
          .form-input {
            width: 240px;
          }
        }
      }
      .form-button {
        background: #fff;
        padding: 20px 0px 2px 20px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin: 10px 0px;
      }
    }
  }
}
</style>